export default () => {
	const activators = document.querySelectorAll('[data-activates]');
	var isSubmenuActive = false;
	// console.log('activators', activators);

	if (activators.length > 0) {
		activators.forEach((activator) => {
			activator.addEventListener('click', (e) => {
				const targetElement = document.querySelector(`[data-target="${activator.dataset.activates}"]`);
				// console.log('targetElement', targetElement, `[data-target="${activator.dataset.activates}"]`);
				activator.classList.toggle('active');
				if (targetElement) {
					targetElement.classList.toggle('active');
				}

				if( targetElement.classList.contains('active') ) {
					isSubmenuActive = activator.dataset.activates
				}
			});
		});
	}

	window.addEventListener('click', function( evt ) {
		if( isSubmenuActive !== false && evt.target.closest('.nav-pills li') == null ) {
			const targetElement = document.querySelector(`[data-target="${isSubmenuActive}"]`);
			const activator = document.querySelectorAll(`[data-activates="${isSubmenuActive}"]`);
			activator[0].classList.toggle('active');
			targetElement.classList.toggle('active');
		}
	})
}