import Swiper, { Navigation, A11y } from 'swiper';

export default () => {
	window.addEventListener("DOMContentLoaded", function (event) {
		const featuredProducts = document.querySelector('.swiper-featured-products');
		if (typeof featuredProducts !== 'undefined' && featuredProducts !== null) {
			const swiper = new Swiper(featuredProducts, {
				// configure Swiper to use modules
				modules: [Navigation, A11y],
				// Optional parameters
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				},
				slidesPerView: 2,
				enabled: false,
				rewind: false,
				loop: false,	
				breakpoints: {
					768: {
						enabled: true,
						spaceBetween: 16,
						slidesPerView: 3
					},
					980: {
						enabled: true,
						slidesPerView: 4
					},
					1281: {
						enabled: true,
						slidesPerView: 5
					}
				},
				on: {
					init: function () {
						// const showoff = document.querySelector('[data-index="0"]');
						// showoff.classList.add('active');
					},
				}
			});

			if (swiper.$el) {
				if (swiper.isBeginning && swiper.isEnd) {
					swiper.$el[0].classList.add('center-slides')
				} else {
					swiper.$el[0].classList.remove('center-slides')
				}

				window.addEventListener('resize', function () {
					if (swiper.isBeginning && swiper.isEnd) {
						swiper.$el[0].classList.add('center-slides')
					} else {
						swiper.$el[0].classList.remove('center-slides')
					}
				})
			}
		}
	})
}