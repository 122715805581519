import Swiper, { Navigation, Pagination, A11y } from 'swiper';

export default () => {
	const swiper = new Swiper('.swiper', {
		// configure Swiper to use modules
		modules: [Navigation, Pagination, A11y],
		// Optional parameters
		loop: false,
		slidesPerView: 'auto',
		centeredSlides: true,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev'
		},
		breakpoints: {
			640: {
				slidesPerView: 2,
				centeredSlides: false
			},
			980: {
				slidesPerView: 3,
				centeredSlides: false
			},
			1281: {
				slidesPerView: 4,
				centeredSlides: false
			}
		},
		spaceBetween: 16,
		on: {
			init: function() {
				// const showoff = document.querySelector('[data-index="0"]');
				// showoff.classList.add('active');
			}
		}
	});
}