globalThis.__VUE_OPTIONS_API__ = true;
globalThis.__VUE_PROD_DEVTOOLS__ = false;

import menus from './scripts/menus.js';
import subnavigation from './scripts/subnavigation.js';
import slider from './scripts/slider.js';
import shareCopy from './scripts/share-copy.js'
import globalFooterCtaAnimation from './scripts/global-footer-cta-animation.js';

import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.scss';

import featuredProducts from './scripts/featured-products.js'

import '../scss/app.scss';

menus();
subnavigation();
slider();
shareCopy();
featuredProducts();
globalFooterCtaAnimation();


// window.addEventListener("load", function(event) {
//   const bodyClasses = [... document.body.classList]
// 	console.log('bodyClasses', bodyClasses);
// 	if (bodyClasses.includes('home')) {
// 		import('./scripts/home.js').then(home => {
// 			console.log('home', home);
// 		});
// 	} 
// 	if (bodyClasses.includes('vueTest')) {
// 		import('./scripts/vueApp/index.js').then(vueTest => {
// 			console.log('vueTest', vueTest);
// 		});
// 	}
// });
