import copy from 'copy-to-clipboard';

export default () => {
	const copyLinkButtons = document.querySelectorAll('.copy-link');

	if (copyLinkButtons) {
		copyLinkButtons.forEach((button) => {
			button.addEventListener('click', (e) => {
				const textToCopy = button.getAttribute('data-copy');
				copy(textToCopy, {
					onCopy: afterCopy(),
				});
			});
		});
	}

	function afterCopy() {
		// console.log('copy link');
		if (typeof dataLayer !== 'undefined') {
			dataLayer.push({
				'event': 'copy-link',
				'engagement_type': 'click'
			});
		}
		alert('Link copied to clipboard')
	}
}
