const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

export default () => {
	// Setup state object
	const state = {};

	const searchActivator = document.querySelector('[data-activate="search"]');
	const navActivator = document.querySelector('[data-activate="navigation"]');

	const search = document.querySelector('.header__search--mobile');
	const navigation = document.querySelector('.primary-nav');

	const navScreen = document.querySelector('.nav-screen');

	// Add getters and setters
	// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/defineProperty
	Object.defineProperty(state, "searchActive", {
		get() {
			return this._searchActive;
		},
		set(newState) {
			this._searchActive = newState;
			handleSearchChange();
			handleScreen();
		}
	});
	Object.defineProperty(state, "navigationActive", {
		get() {
			return this._navigationActive;
		},
		set(newState) {
			this._navigationActive = newState;
			handleNavigationChange();
			handleScreen();
		}
	});

	if (searchActivator) {
		searchActivator.addEventListener('click', (e) => {
			// console.log('clicked', e)
			state.searchActive = !state.searchActive;
		});
	}
	if (navActivator) {
		navActivator.addEventListener('click', (e) => {
			// console.log('clicked', e)
			state.navigationActive = !state.navigationActive;
		});
	}
	if (navScreen) {
		navScreen.addEventListener('click', (e) => {
			state.searchActive = false;
			state.navigationActive = false;
		});
	}

	function handleSearchChange() {
		// console.log('state', state);
		if (state.searchActive) {
			state.navigationActive = false;
			searchActivator.classList.add('active');
			search.classList.add('active');
			navScreen.classList.add('active');
		} else {
			searchActivator.classList.remove('active');
			search.classList.remove('active');
			navScreen.classList.remove('active');
		}
	}

	function handleNavigationChange() {
		// console.log('state', state)
		if (state.navigationActive) {
			state.searchActive = false;
			navActivator.classList.add('active');
			navigation.classList.add('active');
			// document.body.classList.add('locked');
			disableBodyScroll(navigation);
		} else {
			navActivator.classList.remove('active');
			navigation.classList.remove('active');
			document.body.classList.remove('locked');
			enableBodyScroll(navigation);
		}
	}

	function handleScreen() {
		// console.log('handleScreen', state);
		if (state.searchActive || state.navigationActive) {
			navScreen.classList.add('active');
		} else {
			navScreen.classList.remove('active');
		}
	}

}