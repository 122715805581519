export default () => {
	window.addEventListener("DOMContentLoaded", function (event) {
		const wordContainer = document.querySelector('[data-words]');
		// console.log('wordContainer', wordContainer)
		const wordAnimation = [
			{ transform: 'translateY(1em)', opacity: '0' },
			{ transform: 'translateY(0)', opacity: '1', offset: 0.2 },
			{ transform: 'translateY(0)', opacity: '1', offset: 0.8 },
			{ transform: 'translateY(-1em)', opacity: '0' },
		]
		const wordTiming = {
			duration: 2000,
			iterations: 1,
			easing: 'ease-in-out',
			fill: 'forwards'
		}
		
		var wordIndex = 0;
		
		if (wordContainer) {
			let words = wordContainer.dataset.words;
			words = words.split(',');
			var myWordAnimation = wordContainer.animate(wordAnimation, wordTiming);
			window.setInterval(() => {
				if (wordIndex < (words.length - 1)) {
					wordIndex++;
				} else {
					wordIndex = 0;
				}
				wordContainer.innerHTML = words[wordIndex];
				myWordAnimation.play();
			}, 3000)
		}
		
	});
}